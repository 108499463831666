import { useEffect, useState } from 'react';

import {
  Column,
  ComboBox,
  Form,
  Grid,
  NumberInput,
  Stack,
  TextArea,
  TextInput,
} from '@carbon/react';
import { Permission, canForAccount } from '@wastewizer/authz';
import {
  setEmptyStringAsUndefined,
  setValueAsFloat,
} from '@wastewizer/ui-utils';
import { sentence } from 'case';
import { useFormContext } from 'react-hook-form';

import {
  ServiceLocationSelectItemWithAccountFragment,
  UserSelectItemFragment,
} from '#fragments';
import {
  ContainerFullByType,
  ContainerGroundType,
  ContainerLoadingMethod,
  ContainerMaterialConsistency,
  ContainerMaterialDensity,
} from '#generated-types';
import { useUser } from '#hooks/useUser';
import {
  useGetAllServiceLocationsQuery,
  useGetDriversForAccountLazyQuery,
} from './_generated';
import { commodityTypes } from './commodityTypes';
import { containerGroundTypes } from './containerGroundTypes';
import { containerLoadingMethods } from './containerLoadingMethods';
import { containerMaterialConsistencies } from './containerMaterialConsistencies';
import { containerMaterialDensities } from './containerMaterialDensities';
import styles from './ContainerSiteForm.module.scss';
import { containerSizes } from './containerSizes';

export type ContainerSiteFormData = {
  serviceLocationId: string;
  name: string;
  emptyContainerWeight: number;
  maxNetWeight: number;
  maxGrossWeight: number;
  size: number;
  commodityType?: string;
  containerFullByType?: ContainerFullByType;
  containerGroundType?: ContainerGroundType;
  containerLoadingMethod?: ContainerLoadingMethod;
  containerMaterialConsistency?: ContainerMaterialConsistency;
  containerMaterialDensity?: ContainerMaterialDensity;
  internalNotes?: string;
  assignedDriverId?: string;
};

export const ContainerSiteForm: React.FunctionComponent = () => {
  const [canUpdateInternalNotes, setCanUpdateInternalNotes] = useState(false);
  const {
    register,
    setValue,
    resetField,
    watch,
    formState: { errors },
  } = useFormContext();
  const user = useUser();
  const { preferences: { weightLabel } = {} } = user;

  // ComboBoxes cannot be registered with destructuring
  register('serviceLocationId', { required: 'Service location is required' });
  const serviceLocationId = watch('serviceLocationId');

  register('size', { required: 'Container size is required' });
  const containerSize = watch('size');

  register('commodityType');
  const commodityType = watch('commodityType');

  register('containerFullByType');
  const containerFullByType = watch('containerFullByType');

  register('containerGroundType');
  const containerGroundType = watch('containerGroundType');

  register('containerLoadingMethod');
  const containerLoadingMethod = watch('containerLoadingMethod');

  register('containerMaterialConsistency');
  const containerMaterialConsistency = watch('containerMaterialConsistency');

  register('containerMaterialDensity');
  const containerMaterialDensity = watch('containerMaterialDensity');

  register('assignedDriverId');
  const assignedDriverId = watch('assignedDriverId');

  const emptyContainerWeight = watch('emptyContainerWeight');
  const maxNetWeight = watch('maxNetWeight');

  const [serviceLocations, setServiceLocations] = useState<
    ServiceLocationSelectItemWithAccountFragment[]
  >([]);

  const fillByTypes = Object.keys(ContainerFullByType).map((c) => ({
    id: ContainerFullByType[c],
    name: ContainerFullByType[c],
  }));

  useGetAllServiceLocationsQuery({
    fetchPolicy: 'cache-and-network',
    onCompleted: (data) => {
      const allowedServiceLocations = data.serviceLocations.reduce(
        (acc, serviceLocation) => {
          if (
            canForAccount(
              user,
              serviceLocation.account.id,
              Permission.CAN_CREATE_CONTAINER_SITES,
            )
          ) {
            acc.push(serviceLocation);
          }
          return acc;
        },
        [],
      );

      setServiceLocations(allowedServiceLocations);

      if (!serviceLocationId && allowedServiceLocations.length === 1) {
        setValue('serviceLocationId', allowedServiceLocations[0].id);
      }
    },
  });

  const [fetchDrivers, { data: driverData }] = useGetDriversForAccountLazyQuery(
    {
      onCompleted: (data) => {
        if (!data.drivers.find((d) => d.id === assignedDriverId)) {
          resetField('assignedDriverId', { defaultValue: undefined });
        }
      },
    },
  );

  useEffect(() => {
    setValue('maxGrossWeight', maxNetWeight + emptyContainerWeight, {
      shouldValidate: false,
    });
  }, [emptyContainerWeight, maxNetWeight, setValue]);

  useEffect(() => {
    if (serviceLocationId && serviceLocations.length) {
      fetchDrivers({
        variables: {
          accountId: serviceLocations.find((sl) => sl.id === serviceLocationId)
            ?.account.id,
        },
      });
    }
  }, [fetchDrivers, serviceLocationId, serviceLocations]);

  useEffect(() => {
    if (serviceLocationId) {
      setCanUpdateInternalNotes(
        canForAccount(
          user,
          serviceLocations.find((sl) => sl.id === serviceLocationId)?.account
            .id,
          Permission.CAN_UPDATE_CONTAINER_SITE_INTERNAL_NOTES,
        ),
      );
    } else {
      setCanUpdateInternalNotes(false);
    }
  }, [serviceLocationId, serviceLocations]);

  return (
    <Form>
      <Stack gap={6}>
        <ComboBox
          id="serviceLocationId"
          data-1p-ignore
          titleText="Service location"
          placeholder="Select service location"
          helperText="The service location for this container site"
          items={serviceLocations}
          itemToString={(item?: ServiceLocationSelectItemWithAccountFragment) =>
            item?.name ?? ''
          }
          shouldFilterItem={(menu) =>
            menu.item.name
              .toLowerCase()
              .includes(menu.inputValue?.toLowerCase())
          }
          selectedItem={serviceLocations.find(
            (sl) => sl.id === serviceLocationId,
          )}
          onChange={(e) => {
            const value = e.selectedItem?.id;
            setValue('serviceLocationId', value);
          }}
          invalid={!!errors.serviceLocationId}
          invalidText={errors.serviceLocationId?.message as string}
        />

        <TextInput
          {...register('name', { required: 'Name is required' })}
          id="name"
          data-1p-ignore
          labelText="Name"
          helperText="The name of the container site"
          invalid={!!errors.name}
          invalidText={errors.name?.message as string}
        />

        <Grid className={styles.grid}>
          <Column sm={2} md={4} lg={8}>
            <NumberInput
              {...register('emptyContainerWeight', {
                required: 'Empty container weight is required',
                min: {
                  value: 0,
                  message: `Empty container weight must be greater than 0`,
                },
                setValueAs: setValueAsFloat,
              })}
              id="emptyContainerWeight"
              label="Empty container weight"
              helperText={`Empty container weight in ${weightLabel.plural}`}
              invalid={!!errors.emptyContainerWeight}
              invalidText={errors.emptyContainerWeight?.message as string}
              min={Number.NEGATIVE_INFINITY}
              max={Number.POSITIVE_INFINITY}
              defaultValue={emptyContainerWeight || 0}
              hideSteppers
              onChange={(_, { value }) =>
                setValue(
                  'emptyContainerWeight',
                  setValueAsFloat(value as string),
                )
              }
            />
          </Column>

          <Column sm={2} md={4} lg={8}>
            <NumberInput
              {...register('maxNetWeight', {
                required: 'Max net weight is required',
                min: {
                  value: 0,
                  message: `Max net weight must be greater than 0`,
                },
                setValueAs: setValueAsFloat,
              })}
              id="maxNetWeight"
              label="Max net weight"
              helperText={`Max net weight in ${weightLabel.plural}`}
              invalid={!!errors.maxNetWeight}
              invalidText={errors.maxNetWeight?.message as string}
              min={Number.NEGATIVE_INFINITY}
              max={Number.POSITIVE_INFINITY}
              defaultValue={maxNetWeight || 0}
              hideSteppers
              onChange={(_, { value }) =>
                setValue('maxNetWeight', setValueAsFloat(value as string))
              }
            />
          </Column>
        </Grid>

        <Grid className={styles.grid}>
          <Column sm={2} md={4} lg={8}>
            <TextInput
              {...register('maxGrossWeight', {
                required: 'Max gross weight is required',
                min: {
                  value: 0,
                  message: `Max gross weight must be greater than 0`,
                },
                max: {
                  value:
                    weightLabel.barbaraV1Max + weightLabel.barbaraV1MaxBuffer,
                  message: `Max gross weight must be less than ${(
                    weightLabel.barbaraV1Max + weightLabel.barbaraV1MaxBuffer
                  ).toFixed(1)} ${weightLabel.plural}`,
                },
                setValueAs: setValueAsFloat,
              })}
              id="maxGrossWeight"
              labelText="Max gross weight"
              helperText={`Max gross weight in ${weightLabel.plural}`}
              invalid={!!errors.maxGrossWeight}
              invalidText={errors.maxGrossWeight?.message as string}
              disabled
            />
          </Column>

          <Column sm={2} md={4} lg={8}>
            <ComboBox
              id="size"
              titleText="Container size"
              placeholder="Select container size"
              helperText="The container size for the container at this site"
              items={containerSizes}
              itemToString={(item?: (typeof containerSizes)[number]) =>
                item?.name ?? ''
              }
              selectedItem={containerSizes.find((s) => s.id === containerSize)}
              onChange={(e) => {
                setValue('size', e.selectedItem?.id);
              }}
              invalid={!!errors.size}
              invalidText={errors.size?.message as string}
            />
          </Column>
        </Grid>

        <Grid className={styles.grid}>
          <Column sm={2} md={4} lg={8}>
            <ComboBox
              id="commodityType"
              titleText="Commodity type"
              placeholder="Select commodity type"
              helperText="The commodity type in the container at this site"
              allowCustomValue
              items={commodityTypes}
              shouldFilterItem={(menu) =>
                menu.item.toLowerCase().includes(menu.inputValue?.toLowerCase())
              }
              selectedItem={commodityType}
              onChange={(e) => {
                setValue(
                  'commodityType',
                  e.inputValue || e.selectedItem || undefined,
                );
              }}
            />
          </Column>

          <Column sm={2} md={4} lg={8}>
            <ComboBox
              id="containerFullByType"
              titleText="Anticipated full by"
              placeholder="Select anticipated full by"
              helperText="The anticipated way the container will become full"
              items={fillByTypes}
              itemToString={(item?: (typeof fillByTypes)[number]) =>
                item ? sentence(item.name) : ''
              }
              selectedItem={fillByTypes.find(
                (f) => f.id === containerFullByType,
              )}
              onChange={(e) => {
                setValue('containerFullByType', e.selectedItem?.id);
              }}
            />
          </Column>
        </Grid>

        <Grid className={styles.grid}>
          <Column sm={2} md={4} lg={8}>
            <ComboBox
              id="containerGroundType"
              titleText="Ground under container"
              placeholder="Select ground type"
              helperText="The type of ground under the container"
              items={containerGroundTypes}
              itemToString={(item?: (typeof containerGroundTypes)[number]) =>
                item?.name ?? ''
              }
              selectedItem={containerGroundTypes.find(
                (c) => c.id === containerGroundType,
              )}
              onChange={(e) => {
                setValue('containerGroundType', e.selectedItem?.id);
              }}
            />
          </Column>

          <Column sm={2} md={4} lg={8}>
            <ComboBox
              id="containerLoadingMethod"
              titleText="Container loading method"
              placeholder="Select loading method"
              helperText="How the container will be loaded"
              items={containerLoadingMethods}
              itemToString={(item?: (typeof containerLoadingMethods)[number]) =>
                item?.name ?? ''
              }
              selectedItem={containerLoadingMethods.find(
                (c) => c.id === containerLoadingMethod,
              )}
              onChange={(e) => {
                setValue('containerLoadingMethod', e.selectedItem?.id);
              }}
            />
          </Column>
        </Grid>

        <Grid className={styles.grid}>
          <Column sm={2} md={4} lg={8}>
            <ComboBox
              id="containerMaterialConsistency"
              titleText="Container material consistency"
              placeholder="Select consistency"
              helperText="The consistency of the material loaded into the container"
              items={containerMaterialConsistencies}
              itemToString={(
                item?: (typeof containerMaterialConsistencies)[number],
              ) => item?.name ?? ''}
              selectedItem={containerMaterialConsistencies.find(
                (c) => c.id === containerMaterialConsistency,
              )}
              onChange={(e) => {
                setValue('containerMaterialConsistency', e.selectedItem?.id);
              }}
            />
          </Column>

          <Column sm={2} md={4} lg={8}>
            <ComboBox
              id="containerMaterialDensity"
              titleText="Container material density"
              placeholder="Select density"
              helperText="The density of the material loaded into the container"
              items={containerMaterialDensities}
              itemToString={(
                item?: (typeof containerMaterialDensities)[number],
              ) => item?.name ?? ''}
              selectedItem={containerMaterialDensities.find(
                (c) => c.id === containerMaterialDensity,
              )}
              onChange={(e) => {
                setValue('containerMaterialDensity', e.selectedItem?.id);
              }}
            />
          </Column>
        </Grid>

        <ComboBox
          id="assignedDriverId"
          data-1p-ignore
          titleText="Assigned driver"
          placeholder="Select driver"
          helperText="The driver assigned to this container site"
          items={driverData?.drivers || []}
          itemToString={(item?: UserSelectItemFragment) => item?.name ?? ''}
          shouldFilterItem={(menu) =>
            menu.item.name
              .toLowerCase()
              .includes(menu.inputValue?.toLowerCase())
          }
          selectedItem={driverData?.drivers.find(
            (d) => d.id === assignedDriverId,
          )}
          onChange={(e) => {
            setValue('assignedDriverId', e.selectedItem?.id);
          }}
          readOnly={!driverData?.drivers.length}
          warn={!driverData?.drivers.length}
          warnText="There are no drivers setup for this account"
        />

        {canUpdateInternalNotes && (
          <TextArea
            {...register('internalNotes', {
              setValueAs: setEmptyStringAsUndefined,
            })}
            id="internalNotes"
            labelText="Internal notes"
          />
        )}
      </Stack>
    </Form>
  );
};
