import { useCallback } from 'react';

import { Edit } from '@carbon/icons-react';
import { IconButton, Loading, Modal } from '@carbon/react';
import { ModalStateManager } from '@wastewizer/ui-components';
import { FormProvider, useForm } from 'react-hook-form';

import { useUser } from '#hooks/useUser';
import { useUpdateContainerSiteMutation } from './_generated';
import { ContainerSiteTableRowFragment } from '../_generated';
import { ContainerSiteForm, ContainerSiteFormData } from '../ContainerSiteForm';

export type EditContainerSiteProps = {
  containerSite: ContainerSiteTableRowFragment;
  afterUpdate: () => void;
};

export const EditContainerSite: React.FunctionComponent<
  EditContainerSiteProps
> = ({ containerSite, afterUpdate }) => {
  const user = useUser();
  const { preferences: { weightUnit } = {} } = user;
  const formMethods = useForm<ContainerSiteFormData>({
    mode: 'all',
    defaultValues: {
      name: containerSite.name,
      serviceLocationId: containerSite.serviceLocation.id,
      emptyContainerWeight: containerSite.emptyContainerWeight,
      maxNetWeight: containerSite.maxNetWeight,
      maxGrossWeight: containerSite.maxGrossWeight,
      size: containerSite.size,
      commodityType: containerSite.commodityType,
      containerFullByType: containerSite.containerFullByType,
      containerGroundType: containerSite.containerGroundType,
      containerLoadingMethod: containerSite.containerLoadingMethod,
      containerMaterialConsistency: containerSite.containerMaterialConsistency,
      containerMaterialDensity: containerSite.containerMaterialDensity,
      internalNotes: containerSite.internalNotes,
      assignedDriverId: containerSite.assignedDriver?.id,
    },
  });

  const [updateContainerSite, { loading: updating }] =
    useUpdateContainerSiteMutation();

  const renderLauncher = useCallback(
    ({ setOpen }) => (
      <IconButton
        label="Edit"
        align="top-right"
        kind="ghost"
        size="sm"
        onClick={() => {
          setOpen(true);
        }}
      >
        <Edit />
      </IconButton>
    ),
    [],
  );

  const renderModal = useCallback(
    ({ open, setOpen }) => (
      <Modal
        open={open}
        preventCloseOnClickOutside
        modalHeading="Edit container site"
        primaryButtonText="Save"
        selectorPrimaryFocus="#serviceLocationId"
        onRequestClose={() => {
          setOpen(false);
        }}
        onRequestSubmit={formMethods.handleSubmit(
          async (data: ContainerSiteFormData) => {
            const { maxNetWeight: _maxNetWeight, ...rest } = data;
            await updateContainerSite({
              variables: {
                id: containerSite.id,
                input: {
                  ...rest,
                  emptyContainerWeight: {
                    unit: weightUnit,
                    value: data.emptyContainerWeight,
                  },
                  maxGrossWeight: {
                    unit: weightUnit,
                    value: data.maxGrossWeight,
                  },
                },
              },
            });

            setOpen(false);
            afterUpdate();
          },
        )}
      >
        <>
          <Loading withOverlay active={updating} />
          <FormProvider {...formMethods}>
            <ContainerSiteForm />
          </FormProvider>
        </>
      </Modal>
    ),
    [formMethods, updating, updateContainerSite, afterUpdate, weightUnit],
  );

  return (
    <ModalStateManager
      renderLauncher={renderLauncher}
      renderModal={renderModal}
      modalKey={`edit-container-site-modal-${containerSite.id}`}
    />
  );
};
